// extracted by mini-css-extract-plugin
export var button = "ContactForm-module--button--ZsXFE typography-module--paragraphSansMedium--G18U2";
export var buttonSuccessWrapper = "ContactForm-module--buttonSuccessWrapper--YBRZi";
export var contactFormContainer = "ContactForm-module--contactFormContainer--PJADq";
export var countryCode = "ContactForm-module--countryCode--ANK73";
export var hidden = "ContactForm-module--hidden--LhuUq";
export var messageError = "ContactForm-module--messageError--qRt9d";
export var mobileSubTitle = "ContactForm-module--mobileSubTitle--vsBxG typography-module--responsiveTitle4--RYCAI";
export var mobileSuccessMessage = "ContactForm-module--mobileSuccessMessage--djjH6";
export var phoneNumber = "ContactForm-module--phoneNumber--CqVl8";
export var phoneWrapper = "ContactForm-module--phoneWrapper--jSnHs";
export var subTitle = "ContactForm-module--subTitle--FQMcJ typography-module--responsiveTitle4--RYCAI";
export var successMobileIcon = "ContactForm-module--successMobileIcon--vXZgp";
export var successMobileTitle = "ContactForm-module--successMobileTitle--QkEMn";
export var textArea = "ContactForm-module--textArea---m21F";
export var title = "ContactForm-module--title--6dI6l typography-module--responsiveTitle3--K+vPf";
export var wrapper = "ContactForm-module--wrapper--OhARQ";