import React, { useState, useMemo, useCallback } from 'react'
import {
  isValidEmail,
  isValidCountryCode,
  isValidPhoneNumber,
  cn,
} from '../../lib/helpers'
import { Input } from '../Input'
import { InfoMessage } from '../InfoMessage'
import Icon from '../icons'
import * as styles from './ContactForm.module.css'

export const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [countryCode, setCountryCode] = useState('+44')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [position, setPosition] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isErrorResponse, setIsErrorResponse] = useState(false)
  const isEmailValid = useMemo(() => isValidEmail(email), [email])
  const isCountryCodeValid = useMemo(
    () => isValidCountryCode(countryCode),
    [countryCode]
  )
  const isPhoneNumberValid = useMemo(
    () => isValidPhoneNumber(phoneNumber),
    [phoneNumber]
  )

  const phoneErrorMessage = useMemo(() => {
    if (!phoneNumber.length) {
      return  'Phone number is required'
    } else if (!isPhoneNumberValid) {
      return 'Please enter a valid phone number.'
    }
    return ''
  }, [phoneNumber])

  const countryCodeErrorMessage = useMemo(() => {
    if (!countryCode.length) {
      return  'Country code is required. '
    } else if (!isCountryCodeValid) {
      return 'Please enter a valid country code. '
    }
    return ''
  }, [countryCode])

  const resetForm = useCallback(() => {
    setShowError(false)
    setShowSuccess(true)
    setName('')
    setEmail('')
    setCountryCode('+44')
    setPhoneNumber('')
    setPosition('')
    setCompany('')
    setMessage('')
  }, [])

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (
      !isEmailValid ||
      !isCountryCodeValid ||
      !isPhoneNumberValid ||
      !message.length ||
      !name.length
    ) {
      setShowError(true)
    } else {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': event.target.getAttribute('name'),
          name,
          email,
          phoneNumber: countryCode + phoneNumber,
          position,
          company,
          message,
        }),
      })
        .then(() => resetForm())
        .catch(() => {
          setIsErrorResponse(true)
          setShowSuccess(false)
        })
    }
  }

  return (
    <form
      name='contact'
      id='formContact'
      onSubmit={(e) => handleSubmit(e)}
      data-netlify='true'
      method='post'
      netlify-honeypot='bot-field'
      onClick={() => {
        setShowSuccess(false)
        setIsErrorResponse(false)
      }}
    >
      <div className={styles.contactFormContainer}>
        <div className={cn(styles.wrapper, showSuccess && styles.hidden)}>
          <h3 className={styles.title}>
            Contact us
          </h3>
          <input type='hidden' name='form-name' value='contact' />
          <p hidden>
            <label>Don’t fill this out if you’re human:
              <input name='bot-field' />
            </label>
          </p>
          <Input
            value={name}
            onChange={(value) => setName(value)}
            placeholder='Name*'
            name='name'
            error={showError && !name.length}
            aria-label='Name input'
            // required
          />
          {showError && !name.length && (
            <InfoMessage message='Field is required' />
          )}
          <Input
            value={email}
            onChange={(value) => setEmail(value)}
            error={showError && !isEmailValid}
            errorMessage=''
            placeholder= 'E-mail*'
            name='email'
            type='email'
            aria-label='E-mail input'
            // required
          />
          {showError && !isEmailValid && (
            <InfoMessage
              message={
                !email.length
                  ? 'Field is required'
                  : 'Please enter valid email address'
              }
            />
          )}
          <div className={styles.phoneWrapper}>
            <Input
              value={countryCode}
              onChange={(value) => setCountryCode(value)}
              error={showError && !isCountryCodeValid}
              placeholder= '+44'
              name='countryCode'
              type='tel'
              className={styles.countryCode}
              aria-label='Country code input'
              // required
            />
            <Input
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              error={showError && !isPhoneNumberValid}
              placeholder='Phone number*'
              name='countryCode'
              type='tel'
              className={styles.phoneNumber}
              aria-label='Phone number input'
              // required
            />
          </div>
          {showError && (!isCountryCodeValid || !isPhoneNumberValid) && (
            <InfoMessage
              message={countryCodeErrorMessage + phoneErrorMessage}
            />
          )}
          <Input
            value={position}
            onChange={(value) => setPosition(value)}
            placeholder='Position'
            name='position'
            aria-label='Position input'
          />
          <Input
            value={company}
            onChange={(value) => setCompany(value)}
            placeholder= 'Company'
            name='company'
            aria-label='Company input'
          />
          <textarea
            className={cn(
              styles.textArea,
              showError && !message.length && styles.messageError
            )}
            type='text'
            name='message'
            placeholder='Message'
            rows='9'
            cols='50'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            aria-label='Message input'
          />
          {showError && !message.length && (
            <InfoMessage
              message='Field is required'
            />
          )}
          <div className={styles.buttonSuccessWrapper}>
            <button
              className={styles.button}
              type='submit'
              aria-label='submit button'
            >
              Submit
            </button>
            {showSuccess && (
              <h3 className={styles.subTitle}>
                Thank you, your request was successfully submitted!
              </h3>
            )}
          </div>
          {isErrorResponse && (
            <InfoMessage
              message={'Something went wrong, please try again'}
            />
          )}
        </div>
        {showSuccess && (
          <div className={styles.mobileSuccessMessage}>
            <div className={styles.successMobileTitle}>
              <h4 className={styles.mobileSubTitle}>
                Thank you, your request was successfully submitted!
              </h4>
            </div>
            <div className={styles.successMobileIcon}>
              <Icon symbol='mailIcon' />
            </div>
          </div>
        )}
      </div>
    </form>
  )
}
