// extracted by mini-css-extract-plugin
export var addressAndMapWrapper = "ContactView-module--addressAndMapWrapper--fVbaa";
export var addressesText = "ContactView-module--addressesText--qQ3Kh typography-module--paragraphSansMedium--G18U2";
export var addressesTextWrapper = "ContactView-module--addressesTextWrapper--y1y9H";
export var desktopImageWrapper = "ContactView-module--desktopImageWrapper--nuLJm";
export var googleMaps = "ContactView-module--googleMaps--yHM+T";
export var mainBody = "ContactView-module--mainBody--OOQi2";
export var mobileImage = "ContactView-module--mobileImage--F2tVf";
export var mobileSocial = "ContactView-module--mobileSocial--IfWCR";
export var mobileText = "ContactView-module--mobileText--8Jkct";
export var socialMedia = "ContactView-module--socialMedia--y5lDT";
export var text = "ContactView-module--text--ocePD typography-module--paragraphSans--D7MAT";
export var title = "ContactView-module--title--Xus+g typography-module--responsiveTitle4--RYCAI";
export var wrapper = "ContactView-module--wrapper--hRkdj";