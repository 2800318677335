import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageIntro from '../components/page/page-intro2'
import { ContactView } from '../views/Contact/ContactView'
import PropTypes from 'prop-types'

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(slug: { current: { eq: "contact" } }) {
      title
      _rawBody
      excerpt
      mainImage {
        alt
        asset {
          _id
        }
      }
    }
  }
`

const ContactPage = (props) => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page
  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout backgroundColor='#FCF0EA'>
      <SEO title={page.title} />
      <PageIntro
        title={page.title}
        excerpt={page.excerpt}
        centered={true}
        backgroundColor='#FCF0EA'
        textColor='#113D42'
      />
      <ContactView image={page.mainImage} blocks={page._rawBody} />
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title',
    },
  },
}

ContactPage.propTypes = {
  data: PropTypes.object,
  page:PropTypes.object,
  errors: PropTypes.array,
}

export default ContactPage
