import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './GoogleMapsEmbed.module.css'
import { InfoMessage } from './InfoMessage'

const GoogleMapsEmbed = ({ src }) => {
  return (
    <div className={styles.wrapper}>
      {src.includes('https://www.google.com/maps/embed') ? (
        <iframe src={src} className={styles.iframe} loading='lazy' />
      ) : (
        <InfoMessage message='Please check the url of your map' />
      )}
    </div>
  )
}

GoogleMapsEmbed.propTypes = {
  src: PropTypes.string.isRequired,
}

export default GoogleMapsEmbed
