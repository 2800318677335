import React from 'react'
import { ContactForm } from '../../components/contact/ContactForm'
import { Image } from '../../components/Image'
import { SocialMediaIconList } from '../../components/SocialMediaIconList'
import GoogleMapsEmbed from '../../components/GoogleMapsEmbed'
import PropTypes from 'prop-types'
import * as styles from '../Contact/ContactView.module.css'
import { useGetIsMobile } from '../../hooks/useGetIsMobile'
import BlockContent from '../../components/block-content/index'
import Container from '../../components/container'

export const ContactView = ({ image, blocks }) => {
  const isMobile = useGetIsMobile()

  return (
    <Container>
      <div className={styles.mobileImage}>
        <Image mainImage={image} hasRoundedEdges />
      </div>
      <div className={styles.mainBody}>
        <BlockContent blocks={blocks || []} />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <ContactForm />
        </div>
        <div className={styles.desktopImageWrapper}>
          <Image mainImage={image} hasRoundedEdges />
          <div className={styles.socialMedia}>
            <div className={styles.title}>
              Other enquiries
            </div>
            <div className={styles.text}>
                <p>
                  If you wish to send us an e-mail instead, please send it to
                  questions@dignio.com
                </p>
            </div>
            <SocialMediaIconList />
          </div>
        </div>
      </div>

      <div className={styles.socialMedia}>
        <div className={styles.mobileText}>
          <div className={styles.title}>
            Other enquiries
          </div>
          <div className={styles.text}>
            <p>
              If you wish to send us an e-mail instead, please send it to questions@dignio.com
            </p>
          </div>
          {!isMobile && <SocialMediaIconList />}
        </div>
        <div className={styles.addressAndMapWrapper}>
          <div className={styles.addressesTextWrapper}>
            <h3 className={styles.title}>
              Visit our office?
            </h3>
            <div>
              <p className={styles.addressesText}>Oslo:</p>
              <a
                href={'https://goo.gl/maps/RiB2BMqbSNZv1ntv9'}
                target='_blank'
                rel='noreferrer'
              >
                Stenersgata 1A,  0050 Oslo
              </a>
            </div>
          
          </div>
          <div className={styles.googleMaps}>
            <GoogleMapsEmbed src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2000.1934082692628!2d10.744522087249754!3d59.91233744825485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e61f7609bbb%3A0x3fc03374c93ab30c!2sStenersgata%201A%2C%200050%20Oslo!5e0!3m2!1sen!2sno!4v1653029411020!5m2!1sen!2sno' />
          </div>
        </div>
        {isMobile && (
          <div className={styles.mobileSocial}>
            <SocialMediaIconList />
          </div>
        )}
      </div>
    </Container>
  )
}

ContactView.propTypes = {
  image: PropTypes.object,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string,
      _type: PropTypes.string,
      children: PropTypes.array,
      markDefs: PropTypes.array,
      style: PropTypes.string,
    })
  ),
}
